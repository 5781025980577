/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/vue@2.6.12/dist/vue.min.js
 * - /npm/survey-vue@1.9.72/survey-vue.min.js
 * - /npm/survey-vue-ui@1.9.72/survey-vue-ui.min.js
 * - /npm/vue-spinner@1.0.3/dist/vue-spinner.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
